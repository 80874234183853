import styled from "styled-components";

export const StyledContainer = styled.div`
  text-align: left;
  max-width: 1200px;
  margin: 5px auto;
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* position: relative; */
  /* box-sizing: border-box; */
  align-items: center;
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  box-pack: justify;
  /* padding: 2px 8px; */
`;

export const AttachmentsContainer = styled.div`
  /* margin-top: 30px; */
  display: flex;
  flex-direction:row;
  align-items: center;
  margin: 5px 5px;
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 5px 5px;
  padding-right: 2px;
  overflow: auto;
`;

export const AttachmentFileName = styled.div`
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RemoveButton = styled.button`
  margin-left: 5px;
  background-color: hsl(0, 0%, 90%);
  border-color: hsl(0, 0%, 60%);
  border-radius: 4px;
  border-style: hidden;
  &:hover {
    background-color: hsl(0, 0%, 70%);
    border-color: hsl(0, 0%, 60%);
    border-radius: 4px;
    border-style: hidden;

  }
  &:active {
    background-color: hsl(0, 0%, 50%);
    border-color: hsl(0, 0%, 50%);
    border-radius: 4px;
    border-style: hidden;

  }
`;

