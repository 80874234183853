import { htmlToMarkdown } from "components/TextEditor/Parser";
import { IChatLog } from "types/chatLog";
import { AttachmentButton, AttachmentContainer, MessageBodyContent, MessageContainer, MessageContainerHeader, MessageHeaderDate, MessageHeaderIcon, MessageHeaderName } from "./MessageData.styles";
import ReactMarkdown from 'react-markdown';
import { IMessageLogAttachment } from "types/messageLogAttachment";
import { retrieveAttachment } from "services/backend";
import { BiLoader, BiError, BiCheck } from 'react-icons/bi'
import { MessageStatus, MessageType } from "types/messageEnum";
import { useEffect, useState } from "react";

export const MessageData = ({ ...props }) => {
  const [chatLog, setChatLog] = useState<IChatLog[] | null>(null);

  const handleDownload = async (attach: IMessageLogAttachment) => {
    const test = await retrieveAttachment(attach.id, props.accessToken)
    const linkSource = `${test.data.fileType},${test.data.dataBase64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = attach.fileName;
    downloadLink.click();
    return(test);
  }

  useEffect(() => {
    if(props.chatLog) {
      if(props.showSys) {
        setChatLog(props.chatLog);
      } else {
        setChatLog(props.chatLog.filter((message: IChatLog) => message.type !== MessageType.SYSTEM ));
      }
    }
  }, [props.showSys, props.chatLog])

  return (
    <>
      {props.loadingChatLog && chatLog === null ? <MessageContainer>Carregando...</MessageContainer> : null}
      {!props.loadingChatLog && chatLog === null ? <MessageContainer>Não há mensagens</MessageContainer> : null}
      { chatLog !== null ? chatLog.map((data: IChatLog, index: number) => (
        <MessageContainer type={data.type} status={data.status} key={index}>
          <MessageContainerHeader>
            <MessageHeaderName>
              {data.owner} 
              <MessageHeaderIcon>
                {data.status === MessageStatus.CREATED ? <BiLoader size={20} aria-label={"awaiting"} /> : ""}
                {data.status === MessageStatus.REJECTED ? <BiError size={20} aria-label={"rejected"} /> : ""}
                {data.status === MessageStatus.ACCEPTED ? <BiCheck size={20} aria-label={"accepted"} /> : ""}
              </MessageHeaderIcon>
            </MessageHeaderName>
            <MessageHeaderDate>{new Date(data.createDate).toLocaleString()}</MessageHeaderDate>
          </MessageContainerHeader>
          <MessageBodyContent><ReactMarkdown>{(htmlToMarkdown(data.text).replace("\\\n\n\n", "\n\n\\\n"))}</ReactMarkdown></MessageBodyContent>
          {
            data.attachments && data.attachments.length > 0 ? (
              <AttachmentContainer>
                Anexos:
                {data.attachments.map((attach: IMessageLogAttachment, index: number) => <AttachmentButton key={index} onClick={async () => await handleDownload(attach)}>{attach.fileName}</AttachmentButton>)}
              </AttachmentContainer>
            ) : null
          }
        </MessageContainer>
      )) : null}
    </>
  );
};
