import { Attachments } from "components/Attachments";
import { Button } from "components/Button";
import { CenterDiv } from "components/CenterDiv";
import { MessageLog } from "components/MessageLog";
import { StatusMessage } from "components/StatusMessage";
import { TextEditor } from "components/TextEditor/TextEditor";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  newMessage,
  retrieveMessages,
  retrieveResolutionCodes,
  retrieveTicket,
} from "services/backend";
import { IChatLog } from "types/chatLog";
import { EditorContentChanged } from "types/editorContentChanged";
import { IMessageAttachment } from "types/messageAttachment";
import { EditorContainer, MainDiv, SpanWarn } from "./Chat.styles";
import { ToggleButton } from "components/ToggleButton";
import { Select } from "components/Select";
import { severityList, ticketOwnerList } from "utils/formOptions";
import { ISelectOption } from "types/selectOption";
import { TicketInfo } from "components/TicketInfo";
import { TicketStatus } from "types/ticketStatusEnum";

export const Chat = ({ accessToken }: any) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editorHtmlValue, setEditorHtmlValue] = useState<string>("");
  const [editorMarkdownValue, setEditorMarkdownValue] = useState<string>("");
  const [attachments, setAttachments] = useState<IMessageAttachment[] | null>(
    null
  );
  const [requestMessage, setRequestMessage] = useState<string>("");
  const [requestFail, setRequestFail] = useState<boolean>(false);
  const [chatLog, setChatLog] = useState<IChatLog[] | null>(null);
  const [loadingChatLog, setLoadingChatLog] = useState<boolean>(false);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const [updateTextEditor, setUpdateTextEditor] = useState<boolean>(true);
  const [toggleUpdate, setToggleUpdate] = useState<boolean>(false);
  const [toggleResolve, setToggleResolve] = useState<boolean>(false);
  const [toggleClose, setToggleClose] = useState<boolean>(false);
  const [ticketOwner, setTicketOwner] = useState<ISelectOption | null>(null);
  const [severity, setSeverity] = useState<ISelectOption | null>(null);
  const [ticketData, setTicketData] = useState<any | null>(null);
  const [loadingInfo, setLoadingInfo] = useState<boolean>(true);
  const [resolutionCode, setResolutionCode] = useState<ISelectOption | null>(
    null
  );
  const [resolutionCodeList, setResolutionCodeList] = useState<
    ISelectOption[] | null
  >(null);
  const [loadingResolutionCodeList, setLoadingResolutionCodeList] =
    useState<boolean>(false);
  const [ticketStatus, setTicketStatus] = useState<TicketStatus>(
    TicketStatus.Update
  );

  // Busca Lista de tecnologias (techlist)
  useEffect(() => {
    if (accessToken) {
      setLoadingResolutionCodeList(true);
      retrieveResolutionCodes(accessToken).then((res) => {
        setResolutionCodeList(res.data);
        setLoadingResolutionCodeList(false);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (toggleResolve && !toggleClose) {
      setTicketStatus(TicketStatus.Resolved);
    } else if (toggleClose && !toggleResolve) {
      setTicketStatus(TicketStatus.Closed);
    } else {
      setTicketStatus(TicketStatus.Update);
    }
  }, [toggleResolve, toggleClose]);

  useEffect(() => {
    if (!toggleClose) setResolutionCode(null);
  }, [toggleClose]);

  useEffect(() => {
    if (chatLog && id && accessToken) {
      const interval = setInterval(
        () =>
          retrieveMessages(id, accessToken)
            .then((res) => {
              setChatLog(res.data.reverse());
              // setChatLog(res.data.filter((message: IChatLog) => message.type !== MessageType.SYSTEM ).reverse());
            })
            .catch((err) => {
              setLoadingChatLog(false);
            }),
        10000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [accessToken, chatLog, id]);

  useEffect(() => {
    if (ticketData && id && accessToken) {
      const interval = setInterval(
        () =>
          retrieveTicket(id, accessToken)
            .then((res) => {
              setTicketData(res.data);
            })
            .catch((err) => null),
        10000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [accessToken, ticketData, id]);

  useEffect(() => {
    if (accessToken && updateTextEditor && id !== undefined) {
      setLoadingChatLog(true);
      retrieveMessages(id, accessToken)
        .then((res) => {
          setChatLog(res.data.reverse());
          setLoadingChatLog(false);
        })
        .catch((err) => {
          setLoadingChatLog(false);
        });
      setUpdateTextEditor(false);
    }
  }, [updateTextEditor, id, accessToken]);

  useEffect(() => {
    if (accessToken && !ticketData && id !== undefined) {
      retrieveTicket(id, accessToken)
        .then((res) => {
          setTicketData(res.data);
          if (res.status === 200) {
            setLoadingInfo(false);
          } else {
            navigate("/not-found");
          }
        })
        .catch((err) => null);
    }
  }, [ticketData, id, accessToken, navigate]);

  useEffect(() => {
    if (
      accessToken &&
      toggleUpdate &&
      id !== undefined &&
      (!ticketOwner || !severity)
    ) {
      retrieveTicket(id, accessToken)
        .then((res) => {
          setTicketOwner(
            ticketOwnerList.filter(
              (ticket) => ticket.value === res.data.ticketOwner
            )[0]
          );
          setSeverity(
            severityList.filter(
              (ticket) => ticket.value === res.data.severity
            )[0]
          );
        })
        .catch((err) => null);
    }
  }, [toggleUpdate, updateTextEditor, id, accessToken, severity, ticketOwner]);

  const onEditorContentChanged = (content: EditorContentChanged) => {
    setEditorHtmlValue(content.html);
    setEditorMarkdownValue(content.markdown);
  };

  const handleAttachments = (newAttachment: IMessageAttachment) => {
    if (attachments === null) {
      setAttachments([newAttachment]);
    } else {
      const duplicate = attachments.find(
        (obj) =>
          obj.fileName === newAttachment.fileName &&
          obj.dataBase64 === newAttachment.dataBase64
      );
      if (duplicate) {
        return;
      } else {
        const updatedAttachments = [...attachments, newAttachment];
        setAttachments(updatedAttachments);
      }
    }
  };

  useEffect(() => {
    if (!toggleUpdate) {
      setSeverity(null);
      setTicketOwner(null);
    }
  }, [toggleUpdate]);

  const submitMessage = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoadingRequest(true);
    setRequestFail(false);
    setRequestMessage("");

    if (id === undefined) {
      setRequestMessage("undefined topdesk id");
      setRequestFail(true);
      return;
    }

    const request = {
      topdeskId: id,
      text: editorHtmlValue,
      attachments: attachments ? attachments : undefined,
      severity:
        severity &&
        severityList.filter(
          (severity) => ticketData.severity === severity.value
        )[0].label !== severity.label
          ? severity.value
          : null,
      ticketOwner:
        ticketOwner &&
        ticketOwnerList.filter(
          (owner) => ticketData.ticketOwner === owner.value
        )[0].label !== ticketOwner.label
          ? ticketOwner.value
          : null,
      status: ticketStatus,
      resolutionCode: resolutionCode ? resolutionCode.value.code : null,
    };

    console.log(request);
    await newMessage(request, accessToken).then((res) => {
      if (res.status === 201) {
        setRequestMessage(`${res.data.message} - ID: ${res.data.SDCallID}`);
        clearForm();
      } else {
        setRequestMessage(res.data.message);
        setRequestFail(true);
      }
    });
    setLoadingRequest(false);
  };

  const clearForm = (): void => {
    setUpdateTextEditor(true);
    setRequestFail(false);
    setUpdateTextEditor(true);
    setAttachments(null);
    setSeverity(null);
    setTicketOwner(null);
    setToggleClose(false);
    setToggleResolve(false);
    setToggleUpdate(false);
    return;
  };

  return (
    <CenterDiv>
      <MainDiv>
        <TicketInfo data={ticketData} loading={loadingInfo} />
        <EditorContainer>
          <MessageLog
            accessToken={accessToken}
            topdeskId={id}
            chatLog={chatLog}
            loadingChatLog={loadingChatLog}
          />
          <form onSubmit={submitMessage}>
            <TextEditor
              value={editorMarkdownValue}
              onClear={updateTextEditor}
              onChange={onEditorContentChanged}
              onUpload={handleAttachments}
            />
            <Attachments
              attachments={attachments}
              setAttachments={setAttachments}
            ></Attachments>
            <ToggleButton
              isDisabled={toggleResolve || toggleUpdate}
              toggled={toggleClose}
              onClick={setToggleClose}
              label={"Clique para encerrar o ticket"}
            />
            {toggleClose ? (
              <>
                <Select
                  placeholder={"Selecione o código do problema..."}
                  isClearable={true}
                  value={resolutionCode}
                  options={resolutionCodeList ? resolutionCodeList : []}
                  onChange={(option: ISelectOption) =>
                    setResolutionCode(option)
                  }
                  isLoading={loadingResolutionCodeList}
                  isDisabled={loadingResolutionCodeList}
                />
              </>
            ) : null}
            <ToggleButton
              isDisabled={toggleClose || toggleUpdate}
              toggled={toggleResolve}
              onClick={setToggleResolve}
              label={"Clique para resolver o ticket"}
            />
            <ToggleButton
              isDisabled={toggleResolve || toggleClose}
              toggled={toggleUpdate}
              onClick={setToggleUpdate}
              label={"Clique para editar a severidade"}
            />
            {toggleUpdate ? (
              <>
                <Select
                  placeholder={"Selecione a severidade..."}
                  isClearable={true}
                  value={severity}
                  options={[
                    { value: 3, label: "Média" },
                    { value: 4, label: "Baixa" },
                  ]}
                  onChange={(option: ISelectOption) => setSeverity(option)}
                />
                <SpanWarn>
                  Para os casos de Severidade P1-Crítica ou P2-Alta, favor
                  contatar a Cisco diretamente por ligação através do telefone
                  0800-891-4972
                </SpanWarn>
                {/*<Select*/}
                {/*  placeholder={"Selecione o responsável..."}*/}
                {/*  isClearable={true}*/}
                {/*  value={ticketOwner}*/}
                {/*  options={ticketOwnerList}*/}
                {/*  onChange={(option: ISelectOption) => setTicketOwner(option)}*/}
                {/*  isDisabled={ticketOwner ? ticketOwner.label === "CISCO" : false}*/}

                {/*/>*/}
              </>
            ) : null}
            <Button
              disabled={
                loadingInfo || loadingRequest || editorMarkdownValue === ""
              }
            >
              Enviar
            </Button>
            {loadingRequest ? (
              <CenterDiv>
                <p>Carregando...</p>
              </CenterDiv>
            ) : null}
            <StatusMessage fail={requestFail}>{requestMessage}</StatusMessage>
          </form>
        </EditorContainer>
      </MainDiv>
    </CenterDiv>
  );
};
