import styled from "styled-components";
import { MessageStatus, MessageType } from "types/messageEnum";

export const MessageData = styled.div`
  width: 90%;
  margin: 20px 5%;
  margin-top: auto;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 10px 15px;
`;

export const MessageContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px 5%;
  margin-top: 5px;
  margin-top: ${p => p.type === MessageType.ERROR ? "-21px" : "5px"};
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-top:  ${p => p.type === MessageType.ERROR ? "dashed 1px hsl(0, 0%, 80%)" : null};
  border-top-left-radius: ${p => p.type === MessageType.ERROR ? "0px" : null};
  border-top-right-radius: ${p => p.type === MessageType.ERROR ? "0px" : null};
  border-bottom:  ${p => p.status === MessageStatus.REJECTED ? "0px" : null};
  border-bottom-left-radius: ${p => p.status === MessageStatus.REJECTED ? "0px" : null};
  border-bottom-right-radius: ${p => p.status === MessageStatus.REJECTED ? "0px" : null};
  padding: 10px 15px;
`;

export const MessageContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MessageHeaderName = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

export const MessageHeaderIcon = styled.div`
  margin-left: 5px;
`;

export const MessageHeaderDate = styled.div`
  color: hsl(0, 0%, 70%);
`;

export const MessageBodyContent = styled.div`
  margin-top: 30px;
`;

export const AttachmentContainer = styled.div`
  display: flex;
  border-top: 1px solid hsl(0, 0%, 85%);
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
`

export const AttachmentButton = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`

export const StatusContainer = styled.div`
  padding: 10px 10px;
  margin-left: 3px;
  background: ${(props: any) => props.status || "grey"};
`
