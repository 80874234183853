import { IMessageAttachment } from "types/messageAttachment";
import {
  MainContainer,
  StyledContainer,
  AttachmentsContainer,
  RemoveButton,
  AttachmentFileName,
} from "./Attachments.styles";

export const Attachments = ({ ...props }) => {

  function handleRemove(filename: string) {
    const newList = props.attachments.filter((attachment: IMessageAttachment) => attachment.fileName !== filename)
    props.setAttachments(newList);
  }

  return (
    <>
      {props.attachments !== null && props.attachments.length > 0 ? (
        <StyledContainer>
          <MainContainer>
            {props.attachments.map((attachment: IMessageAttachment, index: number) => (
              <AttachmentsContainer key={index}>
                <AttachmentFileName>{attachment.fileName}</AttachmentFileName>
                <RemoveButton onClick={() => handleRemove(attachment.fileName) }>
                  <strong>X</strong>
                </RemoveButton>
              </AttachmentsContainer>
            ))}
          </MainContainer>
        </StyledContainer>
      ) : null}
    </>
  );
};
