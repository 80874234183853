import styled from "styled-components";

// export const MainDiv = styled.div`
//   display: flex;
//   flex-direction: row;
//   margin: 0px 0px;
//   justify-content: center;
// `

export const MainDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const EditorContainer = styled.div`
  width: 100%;
  max-width: 960px;
  text-align: left;
  margin: 0px auto;
  grid-area: 1 / 4 / 5 / 8;
`;

export const SpanWarn = styled.span`
  color: red;
  font-size: small;
  text-align: center;
  min-width: inherit;
`;
