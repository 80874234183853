import { useState } from "react";
import { StyledInput, StyledLabel, StyledText } from "./ToggleButton.styles";

export const ToggleButton = ({ label, toggled, onClick, isDisabled }: any) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <StyledLabel>
      <StyledInput type="checkbox" disabled={isDisabled} checked={toggled} onChange={callback} />
      <span />
      <StyledText>{label}</StyledText>
    </StyledLabel>
  );
};
