import { severityList, ticketOwnerList } from 'utils/formOptions';
import { InfoContainer, MainContainer, StyledContainer } from './TicketInfo.styles';

export const TicketInfo = ({ ...props }) => {
  return (
    <InfoContainer>
      <StyledContainer>
        <MainContainer>
          {!props.loading ? 
          <>
          <h2>Dados do Ticket</h2>
          <p>TopDesk ID: {props.data.topdeskId}</p>
          <p>CISCO ID: {props.data.ciscoId}</p>
          <p>Senha Anexos: {props.data.attPass}</p>
          <p>URL Anexos: https://cxd.cisco.com/home/</p>
          <p>Contrato: {props.data.contract}</p>
          <p>S/N: {props.data.sn}</p>
          <p>Assunto: {props.data.desc}</p>
          <p>Severidade: {severityList.filter(severity => props.data.severity === severity.value)[0].label}</p>
          <p>Responsável: {ticketOwnerList.filter(owner => props.data.ticketOwner === owner.value)[0].label}</p>
          <p>Status: {props.data.status}</p>
          </> : <p>Carregando...</p>}
        </MainContainer>
      </StyledContainer>
    </InfoContainer>
  );
};
