export enum MessageStatus {
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum MessageType {
  DEFAULT = 'DEFAULT',
  SYSTEM = 'SYSTEM',
  ERROR = 'ERROR'
}
