import axios from "axios";
import axiosRetry from "axios-retry";
import { ICodeProblem } from "types/codeProblems";
import { INewMessageRequest } from "types/newMessageRequest";
import { IResolutionCode } from "types/resolutionCode";
import { ISubTechs } from "types/subTechs";
import { ITechs } from "types/techs";
import { ITicketRequest } from "types/ticketRequest";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
});

axiosRetry(instance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export const retrieveTechList = async (accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return instance
    .get(`/cisco/techs`, { headers: headers })
    .then((response) => {
      const options = response.data.map((data: ITechs) => {
        return { value: data, label: `${data.tech_name}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const retrieveSubTechList = async (techId: number, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      techId,
    },
  };

  return instance
    .get(`/cisco/subtechs`, config)
    .then((response) => {
      const options = response.data.map((data: ISubTechs) => {
        return { value: data, label: `${data.sub_tech_name}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const retrieveCodeProblems = async (techId: number, subTechId: number, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      techId,
      subTechId,
    },
  };

  return instance
    .get(`/cisco/codeproblems`, config)
    .then((response) => {
      const options = response.data.map((data: ICodeProblem) => {
        return { value: data, label: `${data.problem_code_description}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const retrieveResolutionCodes = async (accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  };

  return instance
    .get(`/cisco/resolutioncodes`, config)
    .then((response) => {
      const options = response.data.map((data: IResolutionCode) => {
        return { value: data, label: `${data.desc}` };
      });
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...options],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const createTicket = async (request: ITicketRequest, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  return instance
    .post(`/api/ticket`, request, { headers: headers})
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const retrieveTicket = async (id: string, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return instance
    .get(`/api/ticket/${id}`, config)
    .then((response) => {
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const retrieveMessages = async (id: string, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return instance
    .get(`/api/ticket/${id}/messages`, config)
    .then((response) => {
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: [...response.data],
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};

export const retrieveAttachment = async (id: number, accessToken: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return instance
    .get(`/api/attachment/${id}`, config)
    .then((response) => {
      const data = {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
      };
      return data;
    })
    .catch((err) => {
      const error = {
        status: err.response.status,
        ...err.response.data,
      };
      return error;
    });
};


export const newMessage = async (request: INewMessageRequest, accessToken: string) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`
  }
  return instance
    .post(`/api/ticket/message`, request, { headers: headers})
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
