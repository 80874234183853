import { NavBarContainer } from "./NavBar.styles";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export const NavBar = () => {
  return (
    <NavBarContainer>
      <ul>
          <CustomLink to="/form" className="Form">Form</CustomLink>
      </ul>
    </NavBarContainer>
  );
};

function CustomLink({ to, children, ...props }: any) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({path: resolvedPath.pathname, end: true})
  return (
    <>
    <li className={isActive? "active" : ""}>
      <Link to={to} {...props}>{children}</Link>
    </li>
    </>
  )
}
