import { CenterDiv } from "components/CenterDiv"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NotFound = ({ accessToken }: any) => {
  const navigate = useNavigate();
  useEffect(() => {
    if(accessToken) {
      navigate("/");
    }
  }, [accessToken, navigate])

  return (
    <CenterDiv>
      <h1>Página não encontrada</h1>
    </CenterDiv>
  )
}
