import { CenterDiv } from "components/CenterDiv";
import { GreenText, RedText } from "./StatusMessage.styles";

export const StatusMessage = ({ ...props }) => {
  return (
    <CenterDiv>
      {props.fail === true ? <RedText>{props.children}</RedText> : <GreenText>{props.children}</GreenText>}
    </CenterDiv>
  );
};
