import styled from "styled-components";

export const StyledContainer = styled.div`
  text-align: left;
  max-width: 1200px;
  margin: 30px auto;
`;

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  box-pack: justify;
  padding: 2px 8px;
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  width: 100%;
  height: 50vh;
  background-color: hsl(0, 0%, 98%);
  border-top: 1px solid hsl(0, 0%, 98%);
  padding: 2px 8px;
  overflow: auto;
`;


