import styled from "styled-components";

export const InfoContainer = styled.div`
  /* background-color: red;  */
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  padding: 0px 15px;
  grid-area: 1 / 2 / 5 / 4;
`

export const StyledContainer = styled.div`
  text-align: left;
  max-width: 400px;
  margin: 30px 0px;
  margin-right: 0px;
`;

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  box-pack: justify;
  padding: 2px 8px;
`;


