import { CenterDiv } from "components/CenterDiv";
import { MessageData } from "components/MessageData";

import {
  MainContainer,
  MessagesContainer,
  StyledContainer,
} from "./MessageLog.styles";
import { ToggleButton } from "components/ToggleButton";
import { useState } from "react";

export const MessageLog = ({ ...props }) => {
  const [toggleSys, setToggleSys] = useState<boolean>(false);

  return (
    <StyledContainer>
      <MainContainer>
        <CenterDiv>
          <h4>Histórico de mensagens</h4>
        </CenterDiv>
        <MessagesContainer>
          <MessageData showSys={toggleSys} accessToken={props.accessToken} chatLog={props.chatLog} loadingChatLog={props.loadingChatLog}/>
        </MessagesContainer>
        <ToggleButton toggled={toggleSys} onClick={setToggleSys} label={"Mostrar mensagens automáticas CISCO"} />
      </MainContainer>
    </StyledContainer>
  );
};
