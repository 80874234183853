import styled from "styled-components";

export const NavBarContainer = styled.nav`
  /* position: sticky; */
  top: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(204, 204, 204, .5);
  padding: 0 1rem;
  gap: 2rem;
  display: flex;
  align-items: stretch;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
  }
  li.active {
    background-color: #DDD;
  }
  li:hover {
    background-color: #888;
  }
`;
