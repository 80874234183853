import ReactQuill from "react-quill";
import styled from "styled-components";

export const StyledQuill = styled(ReactQuill)`
  .ql-editor {
    min-height: 15vh;
    max-height: 30vh;
    overflow: auto;
  }
`;
