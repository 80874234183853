import { useIsAuthenticated } from "@azure/msal-react";
import { Header } from "components/Header";
import { NavBar } from "components/NavBar";
import { AccessDenied } from "pages/AccessDenied";
import { Chat } from "pages/Chat";
import { NotFound } from "pages/NotFound";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export function MainRouter({ RequestAccessToken }: any) {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const isAuthenticated = useIsAuthenticated();
  
  useEffect(() => {
    if (isAuthenticated && accessToken === null) {
      RequestAccessToken(setAccessToken);
    }
  }, [accessToken, RequestAccessToken, isAuthenticated]);
  
  return (
    <BrowserRouter>
      <Header />
      <NavBar />
      <Routes>
        <Route path="/chat/:id" element={accessToken ? <Chat accessToken={accessToken} /> : <AccessDenied />} />
        <Route path="*" element={<NotFound accessToken={accessToken} />}/>
      </Routes>
    </BrowserRouter>
  );
}
