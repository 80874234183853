import { Container, Image } from "./Header.styles";
import teltecLogo from "assets/header/logo-primary.png";
import { AuthButton } from "components/AuthButton";

export const Header = () => {
  return (
    <Container>
      <Image src={teltecLogo} className="Logo" alt="logo" />
      <AuthButton />
    </Container>
  );
};
