export const ticketOwnerList = [
  { value: 'Shadow', label: 'Teltec Solutions' },
  { value: 'Escalated', label: 'CISCO' }
]

export const severityList = [
  { value: 1, label: 'Crítica' },
  { value: 2, label: 'Alta' },
  { value: 3, label: 'Média' },
  { value: 4, label: 'Baixa' },
]
