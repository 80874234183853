import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { markdownToHtml, htmlToMarkdown } from "./Parser";
import { EditorProps } from "types/editorProps";
import { StyledQuill } from "./TextEditor.styles";
import "react-quill/dist/quill.snow.css";
import { QuillToolbar, modules } from "./EditorToolbar";
import { IMessageAttachment } from "types/messageAttachment";

export const TextEditor = (props: EditorProps) => {
  const [value, setValue] = useState<string>(markdownToHtml(props.value || ""));
  const [attachment, setAttachment] = useState<IMessageAttachment | null>(null)
  const reactQuillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (attachment) {
      props.onUpload(attachment)
      setAttachment(null);
    }
  }, [attachment, props, props.onUpload]);

  const onChange = (content: string) => {
    setValue(content);
    if (props.onChange) {
      props.onChange({
        html: content,
        markdown: htmlToMarkdown(content),
      });
    }
  };

  useEffect(() => {
    if(props.onClear) {
      setValue("")
    }
  }, [props.onClear])

  const fileHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    // input.setAttribute("multiple", "multiple"); // ! Corrigir
    // input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files === null) return;
      for(let i = 0; i < input.files.length; i++) {
        const file = input.files[i]
        const formData = new FormData();
        formData.append("file", file);
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (typeof reader.result === "string") {
            if(input.files === null) return;
            const fileData = {
              fileName: file.name,
              fileType: reader.result?.split(",")[0],
              dataBase64: reader.result?.split(",")[1],
            };
            setAttachment(fileData)
          }
        };
      }
    };
  };

  modules.toolbar.handlers = { attachment: fileHandler };

  return (
    <div className="text-editor">
      <QuillToolbar />
      <StyledQuill
        ref={reactQuillRef}
        theme="snow"
        placeholder="Digite a mensagem..."
        modules={modules}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
